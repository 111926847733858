import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Markdown from 'react-markdown';
import shortcodes from 'remark-shortcodes';
import remarkSubSuper from 'remark-sub-super';

import Button from 'components/common/button/Button';
import { Link } from 'components/common/link/Link';
import Partners from 'components/directus/partners/Partners';
import PrivacyPolicyTable from 'components/directus/privacy-policy-table/PrivacyPolicyTable';
import priceHook from 'hooks/price/price.hook';
import reviewStatsHook from 'hooks/review-stats/review-stats.hook';
// eslint-disable-next-line import/no-cycle
import EligibleCountries from 'components/directus/eligible-countries/EligibleCountries';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';
import { externalCheck } from 'utils/external-check';
import { getSolidFontAwesomeIcon } from 'utils/font-awesome/solid';
import { getSpecialOfferDate } from 'utils/special-offer';
import { Props, BlockquoteProps } from './Markdown.interface';

import {
  markdownList___ordered,
  markdownList,
  markdownList__item,
  markdownTelephone,
  markdownBox,
  markdownBlock,
  markdown__button,
} from './Markdown.scss';

export default ({ source, className, container, listItemIcon }: Props): JSX.Element => {
  const renderer = {
    link: ({ href, children }: any) => {
      if (externalCheck(href)) {
        return (
          <a href={href} target="_blank" rel="noreferrer noopener">
            {children}
          </a>
        );
      }

      return <Link to={href}>{children}</Link>;
    },
    image: ({ src, alt }: any): any => <img alt={alt} src={src} />,
    blockquote: ({ children }: BlockquoteProps) => <blockquote className={markdownBlock}>{children}</blockquote>,
    list: ({ ordered, children }: any) => {
      if (ordered) {
        return <ol className={markdownList___ordered}>{children}</ol>;
      }

      if (listItemIcon) {
        return <ul className={markdownList}>{children}</ul>;
      }

      return <ul>{children}</ul>;
    },
    listItem: ({ children }: any) => {
      if (listItemIcon) {
        return (
          <li className={markdownList__item}>
            {listItemIcon} <span>{children}</span>
          </li>
        );
      }

      return <li>{children}</li>;
    },
    shortcode: ({ identifier, attributes }: any) => {
      switch (identifier) {
        case 'fontawesome':
          return (
            <FontAwesomeIcon
              icon={getSolidFontAwesomeIcon(attributes.icon)}
              color={attributes.color ? attributes.color : false}
              size={attributes.size ? attributes.size : '1x'}
            />
          );
        case 'privacy-policy-table':
          return <PrivacyPolicyTable />;
        case 'price':
          return <>&pound;{getFieldBySlug(attributes.slug, priceHook()).node.value}</>;
        case 'button':
          return (
            <Button to={attributes.link} role="secondary">
              {attributes.text}
            </Button>
          );
        case 'telephone':
          if (attributes.icon && attributes.icon === 'true') {
            return (
              <span className={markdownTelephone}>
                <FontAwesomeIcon icon={getSolidFontAwesomeIcon('faPhoneAlt')} />
                <a href="tel:+442078719990">020 7871 9990</a>
              </span>
            );
          }
          return <a href="tel:+442078719990">020 7871 9990</a>;
        case 'break':
          return <br />;
        case 'space':
          return '\xa0';
        case 'reviews':
          switch (attributes.type) {
            case 'reviews':
              return getFieldBySlug(attributes.slug, reviewStatsHook()).node.total_reviews;
            case 'score':
              return getFieldBySlug(attributes.slug, reviewStatsHook()).node.score;
            case 'maxscore':
              return getFieldBySlug(attributes.slug, reviewStatsHook()).node.max_score;
            default:
              return null;
          }
        case 'box':
          return (
            <span className={markdownBox} style={{ backgroundColor: attributes.color ? attributes.color : '#00ACE8' }}>
              {attributes.text}
            </span>
          );
        case 'partners':
          return <Partners />;
        case 'eligiblecountries':
          return (
            // TODO fix dependency cycle
            <EligibleCountries
              trigger={
                <Button
                  className={markdown__button}
                  role="text"
                  style={{ margin: `${attributes.margin || '0 0 2rem;'}` }}
                >
                  <p>{attributes.text ? attributes.text : 'Find out if your country is eligible'}</p>
                </Button>
              }
            />
          );
        case 'special-offer-date':
          return getSpecialOfferDate();
        default:
          return <code>Shortcode {identifier} is missing required attribute(s).</code>;
      }
    },
    sub: 'sub',
    sup: 'sup',
  };

  if (container) {
    return (
      <Markdown
        source={source}
        className={className}
        renderers={renderer}
        plugins={[[shortcodes, { startBlock: '[[', endBlock: ']]', inlineMode: true }], [remarkSubSuper]]}
      />
    );
  }

  return (
    <Markdown
      source={source}
      className={className}
      disallowedTypes={['paragraph', 'heading']}
      unwrapDisallowed
      renderers={renderer}
      plugins={[[shortcodes, { startBlock: '[[', endBlock: ']]', inlineMode: true }], [remarkSubSuper]]}
    />
  );
};

import React from 'react';

// eslint-disable-next-line import/no-cycle
import Markdown from 'components/common/markdown/Markdown';
import Modal from 'components/common/modal/Modal';
import eligibleCountriesHook from 'hooks/eligible-countries/eligible-countries.hook';
import { Props } from './EligibleCountries.interface';

import { countriesList, countriesList__container, countriesList__content } from './EligibleCountries.scss';

const EligibleCountries = ({ trigger }: Props): JSX.Element => {
  const { last_updated, content } = eligibleCountriesHook();

  return (
    <div className={countriesList}>
      <Modal trigger={trigger} lazy containerClass={countriesList__container} span>
        <div>
          <strong>Last updated: {last_updated}</strong>
          <Markdown source={content} className={countriesList__content} container />
        </div>
      </Modal>
    </div>
  );
};

export default EligibleCountries;
